/* eslint-disable no-alert */
export default function (sUrl) {
  const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
  const isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;
  const isIos = /(iP)/g.test(navigator.userAgent);

  // iOS devices do not support downloading. We have to inform user about this.
  if (isIos) {
    window.open(sUrl, '_blank');
    return false;
  }

  // If in Chrome or Safari - download via virtual link click
  if (isChrome || isSafari) {
    // Creating new link node.
    const link = document.createElement('a');
    link.href = sUrl;
    link.setAttribute('target', '_blank');

    if (link.download !== undefined) {
      // Set HTML5 download attribute. This will prevent file from opening if supported.
      const fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
      link.download = fileName;
    }

    // Dispatching click event.
    if (document.createEvent) {
      const e = document.createEvent('MouseEvents');
      e.initEvent('click', true, true);
      link.dispatchEvent(e);
      return true;
    }
  }

  window.open(sUrl, '_blank');
  return true;
}
