import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Overlay, OverlayTrigger, Popover, ProgressBar, Tooltip } from 'react-bootstrap';
import { gql } from '@apollo/client';
import classNames from 'classnames';
import ImageGallery from 'react-image-gallery';
import { Icon } from '@iconify-icon/react';
import StoryChief from '@/storychief';
import getImageSizeSrc from '@/editor/utils/getImageSizeSrc';
import { useImageError } from '@/storychief/hooks';
import getFileMimeTypeDiscreteType from '@/files/utils/getFileMimeTypeDiscreteType';
import useModal from '@/modals/hooks/useModal';
import download from '@/storychief/utils/download';
import getDateObjectWithTimezone from '@/date/getDateObjectWithTimezone';

const propTypes = {
  object: PropTypes.shape({
    id: PropTypes.string,
    mime_type: PropTypes.string,
    url: PropTypes.string,
    alt: PropTypes.string,
    uploading: PropTypes.bool,
    uploadProgress: PropTypes.number,
    error: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    thumbnail: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    length: PropTypes.number,
    source: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    urls: PropTypes.shape({}),
    isShowVideoPoster: PropTypes.bool,
  }).isRequired,
  tailoredObject: PropTypes.shape({
    image: PropTypes.string,
    thumbnail: PropTypes.string,
    info: PropTypes.string,
  }),
  size: PropTypes.oneOf(['thumb', 'medium', 'regular', 'large', 'full']), // 120px, 800px, 1600px, 2000px, 2000px (we use retina images)
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  backgroundImage: PropTypes.bool,
  upscale: PropTypes.bool,
  showDetails: PropTypes.bool,
  showDownloadAction: PropTypes.bool,
  showViewAction: PropTypes.bool,
  actionsSize: PropTypes.string,
  selected: PropTypes.bool,
  actionsDisabled: PropTypes.bool,
  className: PropTypes.string,
  onSelect: PropTypes.func,
  selectNotSupported: PropTypes.bool,
  selectNotSupportedMessage: PropTypes.string,
  onEdited: PropTypes.func,
  onAltChange: PropTypes.func,
  onDelete: PropTypes.func,
  scaledWidth: PropTypes.number,
  scaledHeight: PropTypes.number,
  isDisabled: PropTypes.bool,
  label: PropTypes.string,
  labelDescription: PropTypes.string,
};

const defaultProps = {
  className: '',
  upscale: false,
  size: 'regular',
  backgroundImage: false,
  actionsDisabled: false,
  selected: false,
  selectNotSupported: false,
  selectNotSupportedMessage: undefined,
  onSelect: null,
  onEdited: null,
  onAltChange: null,
  onDelete: null,
  tailoredObject: null,
  showDetails: false,
  showDownloadAction: false,
  showViewAction: false,
  actionsSize: 'md',
  scaledWidth: null,
  scaledHeight: null,
  maxWidth: null,
  maxHeight: null,
  isDisabled: false,
  label: null,
  labelDescription: null,
};

const fragments = {
  image: gql`
    fragment Image on Image {
      __typename
      id
      height
      url
      updated_at
      mime_type
      derived
      source_id
      width
      file_size
      deleted_at
      created_at
      account_id
    }
  `,
  video: gql`
    fragment Video on Video {
      __typename
      id
      height
      length
      url
      updated_at
      mime_type
      width
      thumbnail
      file_size
      deleted_at
      created_at
      account_id
    }
  `,
  document: gql`
    fragment Document on Document {
      __typename
      id
      url
      file_name
      updated_at
      mime_type
      file_size
      document_thumbnail
      info
      number_of_pages
      deleted_at
      created_at
      account_id
    }
  `,
};
fragments.imageWithPolicies = gql`
  fragment ImageWithPolicies on Image {
    ...Image
    can {
      delete
    }
  }
  ${fragments.image}
`;

fragments.videoWithPolicies = gql`
  fragment VideoWithPolicies on Video {
    ...Video
    can {
      delete
    }
  }
  ${fragments.video}
`;

function MediaItem({
  object,
  size,
  upscale,
  backgroundImage,
  selected,
  className,
  actionsDisabled,
  isDisabled,
  label,
  labelDescription,
  selectNotSupported,
  selectNotSupportedMessage,
  onSelect,
  onEdited,
  onAltChange,
  onDelete,
  showDetails,
  showDownloadAction,
  showViewAction,
  actionsSize,
  scaledWidth,
  scaledHeight,
  tailoredObject,
  maxHeight,
  maxWidth,
}) {
  // State
  const [showAltEditForm, setShowAltEditForm] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [alt, setAlt] = useState(object.alt || '');
  const objectType = getFileMimeTypeDiscreteType(object);
  let objectError = object.error;
  // Variables
  const url = useMemo(() => {
    if (objectType === 'image') {
      if (tailoredObject) {
        return tailoredObject.image;
      }

      if (object.source === 'unsplash' && object.urls) {
        const unsplashMapping = {
          thumb: 'thumb',
          medium: 'small',
          regular: 'regular',
          large: 'regular',
          full: 'full',
        };
        return object.urls[unsplashMapping[size]];
      }

      return getImageSizeSrc(object.url, size, true);
    }
    return object.url;
  }, [object.url, tailoredObject, size]);

  const width = scaledWidth || object.width;
  const height = scaledHeight || object.height;

  const customStyles = backgroundImage ? { backgroundImage: `url(${url})` } : null;
  const selectable = !!(onSelect && !object.uploading);
  const classNameComponent = classNames(`media-item ${className} ${objectType}`, {
    'media-item--selected': selected,
    'media-item--selectable': selectable,
    'media-item--select-not-supported': selectNotSupported,
    'media-item--disabled': isDisabled && !selectNotSupported,
  });
  const classNameResponsive = classNames(`img-responsive`, {
    'img-responsive--upscale': upscale,
  });
  const actionsClassName = classNames(
    'media-item__actions',
    `media-item__actions--${selectable ? 'left' : 'right'}`,
    `media-item__actions--${actionsSize}`,
  );

  const imageErrorAsync = useImageError(objectType === 'image' ? url : null);
  if (imageErrorAsync) {
    objectError = true;
  }

  const imageErrorAsyncPlaceholder = useMemo(() => {
    if (objectError) {
      const canvas = document.createElement('canvas');
      canvas.height = height || 1000;
      canvas.width = width || 1500;
      const ctx = canvas.getContext('2d');
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      return canvas.toDataURL('image/png');
    }
    return null;
  }, [objectError]);

  // Hooks
  const modal = useModal('ImageEditModal');
  const modalView = useModal('ImageViewModal');

  // Refs
  const altButton = useRef();
  const videoRef = useRef(null);
  const labelRef = useRef(null);

  // Side-effects
  useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.load();
    }
  }, [object.url]);

  // functions
  function handleSaveAlt(event) {
    event.preventDefault();
    onAltChange(alt);
    setShowAltEditForm(false);
  }

  function handleAltOnChange(event) {
    setAlt(event.target.value);
  }

  function handleOnEdit() {
    switch (objectType) {
      case 'image': {
        modal.toggle({
          props: {
            image: {
              url: object.url,
              id: object.id,
            },
            maxDimensions:
              maxWidth && maxHeight ? { width: maxWidth, height: maxHeight } : undefined,
            onCompleted: onEdited,
          },
        });
        break;
      }
      default:
    }
  }

  function handleOnDownload() {
    download(object.url);
  }

  function handleOnView() {
    switch (objectType) {
      case 'image': {
        modalView.toggle({
          props: {
            url: object.url,
          },
        });
        break;
      }
      default:
    }
  }

  function getActionsMenu() {
    if (onAltChange || onDelete || onEdited || onSelect || showDownloadAction || showViewAction) {
      return (
        <div className={actionsClassName}>
          {selected && (
            <div className="faux-checkbox faux-checkbox--checked">
              <Icon icon="fa:check" className="text-xs" />
            </div>
          )}
          {selectable && (
            <input
              type="checkbox"
              onClick={onSelect}
              defaultChecked={selected}
              className="sr-only"
            />
          )}
          {!!(onAltChange && !objectError && !object.uploading) && (
            <>
              <button
                title="Add alt tag"
                type="button"
                disabled={actionsDisabled}
                className="btn-chromeless leading-[0]"
                ref={altButton}
                onClick={() => setShowAltEditForm(true)}
              >
                <Icon icon="fa:eye" className="m-1" width="16" height="16" />
                <span className="sr-only">Add alt tag</span>
              </button>
              <Overlay
                show={showAltEditForm}
                onHide={() => setShowAltEditForm(false)}
                rootClose
                placement="top"
                target={altButton.current}
              >
                <Popover id="popover-alt-text">
                  <form className="form-inline" onSubmit={handleSaveAlt}>
                    <div className="form-group form-group-sm">
                      <input
                        autoFocus
                        type="text"
                        className="text form-control"
                        onChange={handleAltOnChange}
                        placeholder="Enter alt tag"
                        value={alt}
                      />
                    </div>
                    <button
                      disabled={object.alt === alt}
                      className="url-input-save btn btn-small btn--rectangle btn-primary"
                      type="submit"
                    >
                      Save
                    </button>
                  </form>
                </Popover>
              </Overlay>
            </>
          )}
          {!!(onEdited && !objectError && !object.uploading) && (
            <button
              title={`Edit ${objectType}`}
              type="button"
              disabled={actionsDisabled}
              onClick={handleOnEdit}
              className="btn-chromeless leading-[0]"
            >
              <Icon icon="fa:magic" className="m-1" width="16" height="16" />
              <span className="sr-only">Edit {objectType}</span>
            </button>
          )}
          {showViewAction && (
            <button
              title={`View ${objectType}`}
              type="button"
              disabled={actionsDisabled}
              onClick={handleOnView}
              className="btn-chromeless leading-[0]"
            >
              <Icon icon="fa:search-plus" className="m-1" width="16" height="16" />
              <span className="sr-only">View {objectType}</span>
            </button>
          )}
          {showDownloadAction && (
            <button
              title={`Download ${objectType}`}
              type="button"
              disabled={actionsDisabled}
              onClick={handleOnDownload}
              className="btn-chromeless leading-[0]"
            >
              <Icon icon="fa:download" className="m-1" width="16" height="16" />
              <span className="sr-only">Download {objectType}</span>
            </button>
          )}
          {onDelete && (
            <button
              title={`Delete ${objectType}`}
              type="button"
              disabled={actionsDisabled}
              onClick={onDelete}
              className="btn-chromeless leading-[0]"
            >
              <Icon icon="fa:trash" className="m-1" width="16" height="16" />
              <span className="sr-only">Delete {objectType}</span>
            </button>
          )}
        </div>
      );
    }
    return <></>;
  }

  function getObjectRenderElement() {
    switch (objectType) {
      case 'image': {
        return (
          <>
            {!backgroundImage && (
              <img
                src={!imageErrorAsync ? url : imageErrorAsyncPlaceholder}
                width={width}
                height={height}
                alt={object.alt}
                className={classNameResponsive}
              />
            )}
          </>
        );
      }
      case 'document': {
        const images = tailoredObject?.info
          ? JSON.parse(tailoredObject.info).images
          : [{ index: 1, url: StoryChief.asset('images/placeholders/document.png') }];
        const galleryImages = images.map((item) => ({
          original: item.url,
          index: item.index,
          loading: 'lazy',
        }));

        return (
          <ImageGallery
            items={galleryImages}
            showThumbnails={false}
            showIndex
            showPlayButton={false}
            lazyLoad
            onErrorImageURL={StoryChief.asset('images/placeholders/text.png')}
          />
        );
      }
      case 'video': {
        let thumbnailUrl = tailoredObject?.thumbnail;
        if (!thumbnailUrl) {
          thumbnailUrl =
            typeof object.thumbnail === 'string'
              ? object.thumbnail
              : URL.createObjectURL(object.thumbnail);
        }
        return (
          <>
            {onSelect || object.uploading || object.isShowVideoPoster ? (
              <img
                src={thumbnailUrl}
                className={classNameResponsive}
                width={width}
                height={height}
                alt=""
              />
            ) : (
              <video
                ref={videoRef}
                controls
                width={width}
                height={height}
                poster={thumbnailUrl}
                className={classNameResponsive}
              >
                <source type="video/mp4" src={object.url} width={width} height={height} />
                <track kind="captions" />
              </video>
            )}
          </>
        );
      }
      default:
        return <div />;
    }
  }

  function getTooltipErrorMessage() {
    if (imageErrorAsync) {
      return 'Image could not be loaded.';
    }

    if (object.uploadProgress === 100) {
      return `We were unable to process this ${objectType}. Please upload a smaller  ${objectType}.`;
    }

    return 'An error occurred during the upload. Please try again.';
  }

  function handleOnMouseEnter() {
    setIsHover(true);
  }

  function handleOnMouseLeave() {
    setIsHover(false);
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={classNameComponent}
      style={customStyles}
      onClick={selectable ? onSelect : null}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      {getObjectRenderElement()}
      {!!(typeof object.uploadProgress === 'number' && !object.error) && (
        <ProgressBar
          active
          className={object.uploadProgress === 100 ? 'progress--done' : ''}
          striped
          now={object.uploadProgress}
          style={{
            height: 8,
            marginBottom: 0,
          }}
        />
      )}
      {objectError && (
        <div className="media-item__overlay media-item__overlay--error">
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="help-description">{getTooltipErrorMessage()}</Tooltip>}
          >
            <div className="gutter-2 space-1 space-top-1">
              <em className="icon-attention-circled big" />
            </div>
          </OverlayTrigger>
        </div>
      )}
      {!!(object.length && showDetails) && (
        <div className="media-item__details">
          <div className="label label-default pull-right">
            <Icon icon="fa:play" className="mr-1 text-xxs" inline />
            {object.length &&
              getDateObjectWithTimezone()
                .startOf('day')
                .plus({ seconds: object.length })
                .toFormat('mm:ss')}
          </div>
        </div>
      )}
      {getActionsMenu()}
      {selectNotSupported && (
        <div className="media-item__overlay media-item__overlay--select-not-supported">
          <div className="label label-info position-relative" ref={labelRef}>
            Not supported
            {selectNotSupportedMessage && (
              <Overlay
                placement="bottom"
                show={isHover}
                target={() => labelRef.current}
                onHide={() => {}}
                rootClose
              >
                <Tooltip id="media-item-support-message" style={{ pointerEvents: 'none' }}>
                  {selectNotSupportedMessage}
                </Tooltip>
              </Overlay>
            )}
          </div>
        </div>
      )}
      {!!label && !!labelDescription && (
        <div className="media-item__label">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="media-item-label">{labelDescription}</Tooltip>}
          >
            <div className="label label-default" ref={labelRef}>
              {label}
            </div>
          </OverlayTrigger>
        </div>
      )}
    </div>
  );
}

MediaItem.propTypes = propTypes;
MediaItem.defaultProps = defaultProps;
MediaItem.fragments = fragments;

export default MediaItem;
